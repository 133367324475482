$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$retina : "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

@import "_sass/home";
@import "_sass/fonts";
@import "_sass/bootstrap-grid";
