body {
  margin: 0px;
  background: url('/img/header-bg.jpg') no-repeat top;
  color: #333;
  font-family: 'SourceSansProNormal';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2 {
    margin-bottom: 20px;
  }

  h2 {
    color: #4C4C4C;
    font-family: SourceSansProBold;
    font-size: 40px;
  }

  p {
    margin: 0 0 10px;
  }

  a {
    color: #FF4081;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .section {
    margin: 30px 0;
    padding: 45px 0;
    position: relative;
    @media (max-width: $screen-md){
      text-align: center;
      img {
        margin: 20px auto 40px;
      }
    }

    @media (max-width: $screen-sm) {
      margin: 0;
      padding:  0;
    }

    p {
      color: #444444;
      font-size: 18px;
      line-height: 1.7em;
    }

    &.section-last {
      margin: 0;
      padding: 0 25px;
    }

    .description {
      @media (max-width: $screen-md) {
        top: 0;
      }
    }

    .centered {
      text-align: center;
      img {
        margin: 0 auto;
      }
    }
    .bottom-logo {
      background: url('/img/footer-logo.png') no-repeat center;
      background-size: 153px 154px;
      height: 154px;
      margin: 20px auto;
      width: 200px;

      @media #{$retina} {
        background: url('/img/footer-logo@2x.png') no-repeat center;
        background-size: 153px 154px;
      }
    }

    .credits {
      padding-bottom: 5px;

      .credits-text {
        font-size: 16px;
        color: #9B9B9B;
        display: inline-block;
        padding-bottom: 5px;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }

      .credits-microblink {
        margin: 0 auto 90px;
        a {
          background: url('/img/footer-microblink-logo.png') no-repeat right;
          background-size: 40px 50px;
          padding-right: 60px;
          padding-bottom: 0;
          line-height: 50px;

          @media #{$retina}{
            background: url('/img/footer-microblink-logo@2x.png') no-repeat right;
            background-size: 40px 50px;
          }

          @media (max-width: $screen-sm) {
            width: 100%;
            padding-right: 0;
            padding-bottom: 40px;
            background-position: 50% 100%;
          }

        }
      }
      .credits-infinum {
        margin: 50px auto 16px;
        a {
          background: url('/img/footer-infinum-logo.png') no-repeat right;
          background-size: 105px 16px;
          padding-right: 112px;

          @media #{$retina} {
            background: url('/img/footer-infinum-logo@2x.png') no-repeat right;
            background-size: 105px 16px;
          }

          @media (max-width: $screen-sm) {
            width: 100%;
            padding-right: 0;
            padding-bottom: 24px;
            background-position: 50% 100%;
          }
        }
      }
    }
  }
  .scanner{
    margin-top: 0;
    @media (max-width: $screen-md) {
      padding-top: 0!important;
    }
    .scanner-desc {
      h1 {
        background: url('/img/header-logo.png') no-repeat left center;
        background-size: 142px 141px;

        @media #{$retina} {
          background: url('/img/header-logo@2x.png') no-repeat left center;
          background-size: 142px 141px;
        }
        height: 142px;
        min-height: 142px;
        padding: 35px 0 0 170px;
        text-transform: uppercase;
        font-size: 55px;
        font-family: SourceSansProLight;
        font-weight: 400;

        @media (max-width: $screen-md), (max-width: $screen-sm)  {
          height: 260px;
          background: url('/img/header-logo.png') no-repeat center center;
          background-size: 142px 141px;
          @media #{$retina} {
            background: url('/img/header-logo@2x.png') no-repeat center center;
            background-size: 142px 141px;
          }

          min-height: 142px;
          padding: 200px 0 0 0;
          text-transform: uppercase;
        }
      }
      @media (min-width: $screen-md) {
        top: 110px;
      }
      .centered-on-smaller {
        display: inline-block;
        margin: 30px auto;

        .app-store {
          background: url('/img/app-store-badge.png') no-repeat;
          background-size: contain;
          display: block;
          width: 160px;
          height: 54px;
          margin: 15px auto;

          @media #{$retina} {
            background: url('/img/app-store-badge@2x.png') no-repeat;
            background-size: contain;
          }

          @media (min-width: 450px) {
            display: inline-block;
            margin-right: 15px;
          }
        }
        .play-store {
          background: url('/img/play-store-badge.png') no-repeat;
          background-size: contain;
          display: block;
          width: 183px;
          height: 54px;
          margin: 15px auto;

          @media #{$retina} {
            background: url('/img/play-store-badge@2x.png') no-repeat;
            background-size: contain;
          }

          @media (min-width: 450px) {
            display: inline-block;
            margin-left: 15px;
          }
        }
      }
    }
    .header-phone {
      @media (max-width: $screen-md) {
        margin-top: 50px;
      }
      @media (max-width: $screen-sm) {
        margin-bottom: 20px;
      }
      .header-phone-img {
        width: 100%;
        height: 728px;
        background: url('/img/header-phone.png') no-repeat center;
        background-size: contain;
        max-height: 759px;

        @media #{$retina} {
          background: url('/img/header-phone@2x.png') no-repeat center;
          background-size: contain;
        }
      }
    }
  }

  .middle-image-container {
    width: 100%;
    height: 545px;
    max-height: 520px;
    background-size: contain !important;
  }
  .barcode {
    margin-top: 0;
    @media (max-width: $screen-md) {
      margin-top: 0;
      padding-top: 0;
    }

    .phone-2-img {
      background: url('/img/phone-2.png') no-repeat center;

      @media #{$retina} {
        background: url('/img/phone-2@2x.png') no-repeat center;
        background-size: contain;
      }
    }

    .barcode-desc {
      h2 {
        font-family: SourceSansProBold;
        font-size: 40px;
      }
      @media (min-width: $screen-md) {
        top: 100px;
      }
    }
  }

  .bouncer {
    @media (max-width: $screen-md) {
      margin-top: 0;
      padding-top: 0;
    }

    .phone-3-img {
      background: url('/img/phone-3.png') no-repeat center;
      @media #{$retina} {
        background: url('/img/phone-3@2x.png') no-repeat center;
        background-size: contain;
      }
    }

    .bouncer-desc {
      @media (min-width: $screen-md) {
        top: 200px;
      }
    }
  }

  .stats-cont {
    margin-top: 0px;
    padding-top: 25px;
    @media (max-width: $screen-md) {
      margin-top: 0;
      padding-top: 0;
    }

    margin-bottom: 0;
    .stats {
      &.stats-desc {
        margin: 15px 0 30px;
      }

      .stats-img-cont {
        .stats-img {
          border-radius: 10px;
          padding: 10px;
          position: relative;
          margin: 0 auto;
          width: 300px;
          height: 300px;
          img {
            display: block;
          }

          .handle {
            background: url('/img/handle.png') no-repeat center;
            background-size: 39px 7px;
            height: 10px;
            margin-bottom: 10px;

            @media #{$retina} {
              background: url('/img/handle@2x.png') no-repeat center;
              background-size: 39px 7px;
            }
          }
          .box-1-cont {
            background: url('/img/box-1.png') no-repeat center;
            background-size: 131px 216px;
            height: 216px;

            @media #{$retina} {
              background: url('/img/box-1@2x.png') no-repeat center;
              background-size: 131px 216px;
            }
          }

          .box-2-cont {
            position: absolute;
            background: url('/img/box-2.png') no-repeat center;
            background-size: 215px 127px;
            width: 220px;
            height: 130px;
            left: 41px;
            top: 105px;

            @media #{$retina} {
              background: url('/img/box-2@2x.png') no-repeat center;
              background-size: 215px 127px;
            }
          }
        }

        .stats-img-right {
          background-color: #e3e4e5;
          .handle {
            background: url('/img/handle-white.png') no-repeat center;
            background-size: 39px 7px;

            @media #{$retina} {
              background: url('/img/handle-white@2x.png') no-repeat center;
              background-size: 39px 7px;
            }
          }
          p {
            line-height: 0.8em;
            font-family: 'SourceSansProNormal';
          }

          .total {
            font-family: 'SourceSansProNormal';
            font-size: 40px;
            margin-top: 90px;
            span {
              font-size: 19px;
            }
          }

          .age {
            font-weight: bold;
            font-family: 'SourceSansProBold';
            font-size: 18px;
            span {
              font-family: 'SourceSansProNormal';
              font-size: 19px;
            }
          }

          .age-type {
            font-size: 18px;
            font-weight: normal;
            span {
              font-family: 'SourceSansProBold';
              font-size: 18px;
            }
          }
        }

        .stats-img-center {
          background-color: #e2413e;
          & > img {
            margin-top: 75px;
          }
        }

        .stats-img-left {
          background-color: #48b570;
          .gender{
            width: 50%;
            p {
              color: white;
            }
            &.gender-men {
              float: left;
              p {
                text-align: right;
                padding-right: 18px;
              }
            }
            &.gender-female {
              float: right;
              p {
                text-align: left;
                padding-left: 7px;
              }
            }
          }
          .stats-text {
            position: absolute;
            color: white;
            &.stats-text-left{
              bottom: 70px;
              left: 107px;
            }
            &.stats-text-right{
              top: 130px;
              right: 102px;
            }
          }
        }
        margin-bottom: 10px;
      }
    }
  }

  .content-middle {
    background: transparent url('/img/grad-1.png') repeat-x center center;
  }
  .content-lower {
    background: transparent url('/img/grad-2.png') repeat-x center bottom;
  }
}


@keyframes rotating {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

.opl {
  position:absolute;
  top:100px;
  left:0px;
  z-index:99999;
}
.opl a {
  width:73px;
  height:56px;
  text-indent:-9999px;
  display:block;
  background:url('https://s3.amazonaws.com/onepagelove/one-page-love-award-left.png') no-repeat;
}

.developers-cont {
  h2 {
    margin-bottom: 30px;
  }
  h3 {
    margin-top: 40px;
    font-weight: 900;
    color: #666;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .contact-us {
    margin-top: 15px;
  }

  .privacy-policy {
    margin-top: 15px;
  }
}
