@font-face {
  font-family: 'SourceSansProNormal';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/SourceSansPro-Regular.otf');
}

@font-face {
  font-family: 'SourceSansProBold';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/SourceSansPro-Bold.otf');
}

@font-face {
  font-family: 'SourceSansProLight';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/SourceSansPro-Light.otf');
}
